export const field = {
  label: '疾病领域',
  value: 'field',
  unfold: true,
  children: []
}

export const language = {
  label: '语言类型',
  value: 'language',
  children: []
}

export const literature = {
  label: '文献类型',
  value: 'article_type',
  children: []
}

export const publish = {
  label: '发表年份',
  value: 'publish_year',
  children: [],
  range: [1987, 2021],
  valueRange: [1987, 2021]
}

export const impact = {
  label: '影响因子',
  value: 'impact_factor',
  children: [],
  range: [0, 75],
  valueRange: [0, 75]
}

export const collect_time = {
  label: '收藏时间',
  value: 'collect_time',
  children: [],
  range: [],
  valueRange: []
}

<template>
  <div class="date-item">
     <div class="header-label">
      <div class="left">
         {{option.label}}
      </div>
     </div>
      <div class="label-content">
        <div class="slider-wrap">
          <DatePicker :editable="false" v-model="yearOption.valueRange[0]" type="year" placeholder="Start" :clearable="false" :options="startFormat" :style="{width:  small ? '100px' : '120px'}"  @on-change="handleStart"></DatePicker>
          <span style="padding: 0 12px">—</span>
          <DatePicker :editable="false" v-model="yearOption.valueRange[1]" type="year" placeholder="End" :options="endFormat" :clearable="false" :style="{width:  small ? '100px' : '120px'}" @on-change="handleEnd"></DatePicker>
        </div>
      </div>
  </div>
</template>

<script>
import { DatePicker } from 'view-design'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'DateItem',

  data() {
    return {
      yearOption: {},
      small: false
    }
  },

  props: {
    option: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    startFormat() {
      return {
        disabledDate: (date) => {
          const dateVal = date && date.valueOf()
          return dateVal <= new Date(this.option.range[0] - 1, 0) || dateVal >= new Date(this.option.range[1] + 1, 0)
        }
      }
    },
    endFormat() {
      return {
        disabledDate: (date) => {
          const dateVal = date && date.valueOf()
          return dateVal >= new Date(this.option.range[1] + 1, 0) || dateVal <= new Date(this.option.range[0] - 1, 0)
        }
      }
    }
  },

  watch: {
    option: {
      handler(val) {
        this.yearOption = cloneDeep(val)
        this.yearOption.valueRange = this.yearOption.valueRange.map(item => item + '')
        if (window.innerWidth <= 1440) {
          this.small = true
        } else {
          this.small = false
        }
      },
      immediate: true,
      deep: true
    }
  },

  components: {
    DatePicker
  },

  methods: {
    handleChange(val) {
      this.$emit('slider', val)
    },
    handleStart(val) {
      this.$emit('start', val)
    },
    handleEnd(val) {
      this.$emit('end', val)
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>
<template>
  <div class="slider-item">
     <!-- :marks="marks" -->
     <div class="header-label">
      <div class="left">
         {{option.label}}
      </div>
     </div>
      <div class="label-content">
        <div class="start">{{Math.floor(option.range[0])}}</div>
        <div class="slider-wrap">
          <Slider
            v-model="option.valueRange"
            range
            :min="option.range[0]"
            :max="option.range[1]"
            @on-change="handleChange"
            ></Slider>
          </div>
        <div class="end">{{Math.ceil(option.range[1])}}</div>
      </div>
  </div>
</template>

<script>
import { Slider } from 'view-design'
export default {
  name: 'SliderItem',

  props: {
    option: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    Slider
  },

  methods: {
    handleChange(val) {
      this.$emit('slider', val)
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>
<template>
  <div class="labels-card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LabelsCard'
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>
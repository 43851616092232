<template>
  <div class="labels-item">
    <div class="header-label">
      <div class="left">
         {{option.label}}
      </div>
      <div class="right" v-if="isBioMarker && unfold" @mouseenter="isFold">
        更多
        <i class="iconfont iconchakangengduo"></i>
      </div>
    </div>
    <div class="label-content" v-if="!isBioMarker">
      <div class="label"
        v-for="(item, index) in option.children"
        :key="item+index"
        @click="handleLabelClick(index, item)"
        :class="{'active': item.isSelect}"
      >
        {{item.label}}
      </div>
    </div>
    <div class="label-content" v-if="isBioMarker">
      <div class="label"
        v-for="(item, index) in option.children.slice(0, 12)"
        :key="item+index"
        @click="handleLabelClick(index, item)"
        :class="{'active': item.isSelect}"
      >
        {{item.label}}
      </div>
    </div>
    <div class="label-unfold" v-if="isBioMarker && !option.unfold" @mouseleave="isUnFold">
      <div class="label-unfold-content">
        <div class="label"
          v-for="(item, index) in option.children"
          :key="item+index"
          @click="handleLabelClick(index, item)"
          :class="{'active': item.isSelect}"
        >
          {{item.label}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LabelsItem',

  props: {
    option: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    isBioMarker() {
      return this.option.value === 'bio_marker'
    },
    unfold() {
      return this.isBioMarker
        ? this.option.children.length >= 12
        : false
    }
  },

  methods: {
    handleLabelClick(key, item) {
      const { value } = this.option
      const temp = {
        key,
        item,
        value
      }
      this.$emit('label', temp)
    },
    isFold() {
      const { value } = this.option
      this.$emit('fold', value)
    },
    isUnFold() {
      const { value } = this.option
      this.$emit('fold', value)
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>
<template>
  <div class="library-lists-wrap" :style="{ height: lists.length ? 'auto' : '82vh' }">
    <div class="heading">
      <div class="label">我的图书馆</div>
      <div class="right">
        <div class="clear" @click="isClear">
          <i class="iconfont iconshanchu"></i>
          <span>清空筛选</span>
        </div>
      </div>
    </div>
    <div class="library-search-area">
      <div class="search-card-wrap">
        <Input
          v-model="search"
          placeholder="输入关键词查询"
          size="large"
          @on-enter="isSearch"
        />
        <div class="search-button">
          <Button
            size="large"
            type="primary"
            ghost
            @click="isSearch"
          >
            搜索
          </Button>
        </div>
      </div>
    </div>
    <div class="library-wrap">
      <Row :gutter="24">
        <Col span="6">
          <div class="left" v-if="lists.length">
            <div class="skeleton-wrap" v-if="treeLoading">
              <skeleton
                type='listcom'
                active
                :options="skeletonOption"
              />
            </div>
            <template v-else>
              <LabelsCard >
                <LabelsItem
                  :option="filterOption.field"
                  @label="isLabelClick"
                  @fold="isLabelsFold"
                />
              </LabelsCard>
              <LabelsCard v-if="!isSecondCardShow">
                <LabelsItem
                  :option="filterOption.language"
                  @label="isLabelClick"
                />
                <LabelsItem
                  :option="filterOption.article_type"
                  @label="isLabelClick"
                />
              </LabelsCard>
              <LabelsCard >
                <DateItem
                  :option="sliderOption.publish_year"
                  @start="isStartClick"
                  @end="isEndClick"
                />
                <SliderItem
                  :option="sliderOption.impact_factor"
                  @slider="isSliderOfImpact"
                />
                <div class="collect_time">
                  <div class="collect_label">收藏时间</div>
                  <DatePicker
                    type="daterange"
                    v-model="collectDate"
                    placeholder="请选择起始时间"
                    @on-change="handleDate"
                  />
                </div>
              </LabelsCard>
            </template>
          </div>
        </Col>
        <Col span="18">
          <div class="right">
            <template v-if="lists.length">
              <ListCard
                :library="true"
                :count="total"
                :checkAll="checkAll"
                :unsure="unsure"
                :timeLighten="sortTime"
                :timeAsc="timeAsc"
                :timeDesc="timeDesc"
                :collectAsc="collectAsc"
                :collectDesc="collectDesc"
                :ifAsc="ifAsc"
                :ifDesc="ifDesc"
                :collectLighten="sortCollect"
                :iFLighten="sortIF"
                :isSelect="checkAllGroup.length"
                @download="isDownload"
                @time="isTimeSort"
                @impact="isImpactSort"
                @collect="isCollectTimeSort"
                @all="isCheckAll"
              >
              <CheckboxGroup
                v-model="checkAllGroup"
                @on-change="checkAllGroupChange"
              >
                <ListItem
                  v-for="(item, index) in lists"
                  :key="index"
                  :option="item"
                  :itemKey="index"
                  :isLibrary="true"
                  @fold="isFold"
                  @collect="isCardCollect"
                  @more="toggleMore"
                />
              </CheckboxGroup>
            <div class="library-lists-footer">
              <Page
                :current="page"
                :total="total"
                show-elevator
                @on-change="isPageChange"
              />
            </div>
            </ListCard>
            </template>
          </div>
        </Col>
      </Row>
      <div class="lists-no-data" v-if="!lists.length">
        <img src="../../assets/imgs/empty.png">
      </div>
    </div>
  </div>
</template>

<script>
import { fetchCollectLiterature, fetchCollectionsTags } from '@/api'
// import SearchCard from '../literature/components/SearchCard'
import LabelsCard from '../literature/components/LabelsCard'
import LabelsItem from '../literature/components/LabelsItem'
import DateItem from '../literature/components/DateItem'
import SliderItem from '../literature/components/SliderItem'
import ListCard from '../literature/components/ListCard'
import ListItem from '../literature/components/ListItem'
import { CheckboxGroup, Page, Row, Col, DatePicker, Input, Button, Message } from 'view-design'
import cloneDeep from 'lodash/cloneDeep'
import { field, language, literature, publish, impact } from './config'
import {
  setSliderFromRes,
  setFilterFromResponseLibrary,
  setLabelsOption,
  setStringify,
  isChildrenEmpty,
  savePdfWithOpen
} from '@/utils/tools'
import dayjs from 'dayjs'
export default {
  name: 'Library',
  data () {
    return {
      lists: [],
      filterOption: {
        field: cloneDeep(field),
        language: cloneDeep(language),
        article_type: cloneDeep(literature)
      },
      sliderOption: {
        publish_year: cloneDeep(publish),
        impact_factor: cloneDeep(impact)
      },
      collectDate: [],
      unsure: false,
      checkAll: false,
      checkAllGroup: [],
      total: 0,
      page: 1,
      sortTime: false,
      timeAsc: false,
      timeDesc: false,
      sortCollect: false,
      collectAsc: false,
      collectDesc: false,
      sortIF: false,
      ifAsc: false,
      ifDesc: false,
      treeLoading: true,
      skeletonOption: {
        row: 28,
        lineHight: 10
      },
      savedLabels: [],
      search: ''
    }
  },
  components: {
    Input,
    Row,
    Col,
    LabelsCard,
    LabelsItem,
    DateItem,
    SliderItem,
    ListCard,
    ListItem,
    CheckboxGroup,
    Page,
    DatePicker,
    Button
  },

  computed: {
    isPublishYear({ sortTime, timeAsc, sortCollect, sortIF }) {
      return (sortTime && !sortCollect && !sortIF)
        ? timeAsc ? 'article__publish_year' : '-article__publish_year'
        : undefined
    },
    isCreatedTime({ sortCollect, collectAsc, sortTime, sortIF }) {
      return (sortCollect && !sortTime && !sortIF)
        ? collectAsc ? 'created_at' : '-created_at'
        : undefined
    },
    isImFactor({ sortIF, ifAsc, sortTime, sortCollect }) {
      return (sortIF && !sortTime && !sortCollect)
        ? ifAsc ? 'article__impact_factor' : '-article__impact_factor'
        : undefined
    },
    isSecondCardShow({ article_type, language }) {
      return isChildrenEmpty(article_type, language)
    },
    isItmeCollect({ collectDate }) {
      return collectDate[0] ? collectDate.map(v => dayjs(v).format('YYYY-MM-DD')).join(',') : undefined
    },
    getUserSelectLabel() {
      return setLabelsOption(this.filterOption)
    },
    // 文献列表公共的请求参数
    getCommonFilterParams() {
      // publish_year impact_factor
      const { temp: { article_type, language, field }, save } = setStringify(this.getUserSelectLabel)
      this.savedLabels = save.length ? save : []
      // 匹配中文逗号 -> 英文
      const reg = /，/ig
      if (reg.test(this.search)) {
        this.search = this.search.replace(reg, ',')
      }
      const option = {
        page: this.page,
        article__field__id__all: field,
        search: this.search || undefined,
        article__language: language,
        article__article_type__id__all: article_type,
        ordering: this.isPublishYear || this.isCreatedTime || this.isImFactor || undefined,
        article__impact_factor__range: this.sliderInitialImpactor ? undefined : this.sliderOption.impact_factor.valueRange.join(','),
        article__publish_year__range: this.isSameYear ? undefined : this.sliderOption.publish_year.valueRange.join(','),
        created_at__date__range: this.isItmeCollect
      }
      return option
    },
    sliderInitialImpactor() {
      return (this.sliderOption.impact_factor.range[0] === this.sliderOption.impact_factor.valueRange[0]) && (this.sliderOption.impact_factor.range[1] === this.sliderOption.impact_factor.valueRange[1])
    }
  },

  created() {
    this.fetchOriginArticles(undefined, true, true)
  },

  methods: {
    async fetchOriginArticles(option = {}, setLabel = false, clear = false) {
      const res = await fetchCollectLiterature(option)
      const { data, page: { count } } = res.data
      if (!data.length) {
        this.lists = []
        return
      }
      this.total = count
      this.lists = data.map(item => ({
        unfold: false,
        collect: false,
        isMore: false,
        ...item
      }))
      this.treeLoading = true
      fetchCollectionsTags(option).then(tags => {
        clear && setSliderFromRes(tags.data, this.sliderOption)
        clear && setFilterFromResponseLibrary(tags.data, this.filterOption)
        !setLabel && setFilterFromResponseLibrary(tags.data, this.filterOption)
        if (this.savedLabels.length && !setLabel) {
          Object.keys(this.filterOption).forEach(item => {
            this.savedLabels.forEach(label => {
              this.filterOption[item].children.forEach((child, key) => {
                if (child.label === label) {
                  this.filterOption[item].children[key].isSelect = true // tags的id重复
                }
              })
            })
          })
        }
        this.treeLoading = false
      })
    },
    isSearch() {
      if (this.search.trim()) {
        this.fetchOriginArticles(this.getCommonFilterParams)
      } else {
        Message.warning({
          content: '请输入内容后搜索！',
          duration: 2
        })
      }
    },
    isLabelClick(temp) {
      this.page = 1
      const { key, value } = temp
      this.filterOption[value].children[key].isSelect = !this.filterOption[value].children[key].isSelect
      this.fetchOriginArticles(this.getCommonFilterParams)
    },
    isLabelsFold(val) {
      this.filterOption[val].unfold = !this.filterOption[val].unfold
    },
    isDownload() {
      if (this.checkAllGroup.length) {
        const option = {
          id__in: this.checkAllGroup.join(',')
        }
        savePdfWithOpen(option, 'users/collections/export', {}, true)
        this.checkAllGroup = []
        this.checkAll = false
      } else {
        Message.warning({
          content: '请选择要导出的数据！',
          duration: 2
        })
      }
    },
    isTimeSort() {
      this.sortTime = true
      if (!this.timeDesc) {
        this.timeAsc = false
        this.timeDesc = true
      } else {
        this.timeAsc = true
        this.timeDesc = false
      }
      this.sortIF = this.sortCollect = this.ifDesc = this.ifAsc = this.collectAsc = this.collectDesc = false
      this.fetchOriginArticles(this.getCommonFilterParams, true)
    },
    isImpactSort() {
      this.sortIF = true
      if (!this.ifDesc) {
        this.ifAsc = false
        this.ifDesc = true
      } else {
        this.ifAsc = true
        this.ifDesc = false
      }
      this.sortTime = this.sortCollect = this.timeDesc = this.timeAsc = this.collectAsc = this.collectDesc = false
      this.fetchOriginArticles(this.getCommonFilterParams, true)
    },
    isCollectTimeSort() {
      this.sortCollect = true
      if (!this.collectDesc) {
        this.collectAsc = false
        this.collectDesc = true
      } else {
        this.collectAsc = true
        this.collectDesc = false
      }
      this.sortTime = this.sortIF = this.timeDesc = this.timeAsc = this.ifDesc = this.ifAsc = false
      this.fetchOriginArticles(this.getCommonFilterParams, true)
    },
    isCheckAll() {
      if (this.unsure) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.unsure = false
      if (this.checkAll) {
        const temp = []
        this.lists.forEach(item => temp.push(item.id))
        this.checkAllGroup = temp
      } else {
        this.checkAllGroup = []
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.lists.length) {
        this.unsure = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.unsure = true
        this.checkAll = false
      } else {
        this.unsure = false
        this.checkAll = false
      }
    },
    isFold(key) {
      this.lists[key].unfold = !this.lists[key].unfold
    },
    isCardCollect(key) {
      this.lists.splice(key, 1)
      if (!this.lists.length) {
        this.page = 1
      }
      this.fetchOriginArticles(this.getCommonFilterParams)
    },
    toggleMore(key) {
      this.lists[key].isMore = !this.lists[key].isMore
    },
    isPageChange(page) {
      this.checkAllGroup = []
      this.checkAll = false
      this.page = page
      this.fetchOriginArticles(this.getCommonFilterParams, true)
      // const el = document.getElementById('layout')
      // el.scroll(0, 20)
    },
    handleDate(val) {
      this.collectDate = val
      this.fetchOriginArticles(this.getCommonFilterParams, true)
    },
    setCommonInitial() {
      this.savedLabels = []
      this.checkAllGroup = []
      this.sortTime = this.timeAsc = this.timeAsc = false
      this.sortIF = this.ifAsc = this.ifDesc = false
      this.sortCollect = this.collectAsc = this.collectDesc = false
      this.page = 1
      this.checkAll = false
      this.collectDate = []
    },
    isClear() {
      this.search = ''
      this.setCommonInitial()
      this.fetchOriginArticles({}, true, true)
    },
    // 年份点击
    isStartClick(val) {
      this.sliderOption.publish_year.valueRange[0] = val
      this.dateInitialYear()
    },
    isEndClick(val) {
      this.sliderOption.publish_year.valueRange[1] = val
      this.dateInitialYear()
    },
    dateInitialYear() {
      this.isSameYear = (this.sliderOption.publish_year.range[0] === Number(this.sliderOption.publish_year.valueRange[0])) && (this.sliderOption.publish_year.range[1] === Number(this.sliderOption.publish_year.valueRange[1]))
      this.fetchOriginArticles(this.getCommonFilterParams)
    },
    isSliderOfImpact(val) {
      this.sliderOption.impact_factor.valueRange = val
      this.fetchOriginArticles(this.getCommonFilterParams)
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>
